import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import ReactPlayer from 'react-player'

const RecommendedCard = ({ title, subtitle, link, topics, image }) => {
  if (link && link.includes('youtube')) {
    return (
      <div className='card  mb-5'>
        <div className='card-content'>
          <div className='card-image'>
            <div className='player-wrapper'>
              <figure class='image is-16by9'>
                <ReactPlayer
                  light={true}
                  className='react-player has-ratio'
                  width='640'
                  height='360'
                  url={link}
                  onStart={() => {
                    try {
                      typeof window !== 'undefined' &&
                        window.gtag('event', 'play', {
                          event_category: 'Videos',
                          event_label: `${title}`,
                        })
                    } catch (err) {
                      console.error(`failed to track event`, err)
                    }
                  }}
                />
              </figure>
            </div>
          </div>
          <div className='card-content'>
            <div className='content'>
              <p className='title is-size-5-desktop is-size-6-touch has-text-light'>{title}</p>
              <div className='tags'>
                {topics.map(topic => (
                  <span className='tag' key={topic}>
                    {topic}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Link to={link}>
      <div className='card'>
        <div className='card-content'>
          <div className='media'>
            <div className='media-left'>{image}</div>
            <div className='media-content'>
              <h4 className='title is-size-5-desktop is-size-6-touch has-text-light'>{title}</h4>
              <p className='subtitle is-size-5-desktop is-size-6-touch has-text-light'>{subtitle}</p>
              <div className='tags'>
                {topics.map(topic => (
                  <span className='tag' key={topic}>
                    {topic}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

RecommendedCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  link: PropTypes.string,
  topics: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.node,
}

RecommendedCard.defaultProps = {
  link: null,
  topics: [],
  image: null,
}

export default RecommendedCard
